import React from "react"

import Layout from "../components/layout";
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FAQ from '../components/faq'
import SEO from "../components/seo";


import {LaganEngineeringTestimonial} from '../components/testimonial';

const FEATURES = [
	"Facial Recognition",
	"Time Clock Kiosk",
	"Mobile App Clock In",
	"GPS Clock In",
	"Web Browser Clock In",
	"Rota Planner",
	"Manual Timesheets",
	"Configurable Geofences",
	"Track Time on Jobs",
	"Manage Employee Time Off",
	"Interactive Leave Wall Calendar",
	"Job Scheduler",
	"See Who's In and Where",
	"Real-time timesheets",
	"Automatic Calculation of Lateness and Absenteeism",
	"Alerts and Notifications",
	"Add Job Notes, Documents & Photos",
	"Job Photos",
	"Job Signatures",
	"Job Costing Reports",
	"Multi-Level Timesheet Approval Process",
	"Employee and Company Documents",
	"Accountancy Integrations including Xero, Sage, MoneySoft and BrightPay",
	"Friendly UK Support",
	"24/7 Cloud Portal",
	"Instant Messenger (Paid Add-On £39/month or £390/year)",
	"Visitor Book (Paid Add-On £29/month or £290/year)",
	"Personalise with your logo/colours (Paid Add-On £19/month or £190/year)",
	"Working Time Regulations (Paid Add-On £19/month or £190/year)"
]

const Pricing = () => (
	<Layout>
		<SEO
    		title="Pricing"
    		description="Get started with a free 14 day trial. £3.50 per employee per month."
    	 />
		<Navbar />
		<div className="bg-gray-50">
		  <div className="pt-12 sm:pt-16 lg:pt-20">
		    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
		      <div className="text-center">
		        <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
		          £3.50 per employee per month
		        </h2>
		        <p className="mt-4 text-xl leading-7 text-gray-500">
		          No upfront fees, no hidden charges. 14 day free trial. No credit card required.
		        </p>
		      </div>
		    </div>
		  </div>
		  <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
		    <div className="relative">
		      <div className="absolute inset-0 h-1/2 bg-white"></div>
		      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
		        <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
		          <div className="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
		            <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
		              One Simple Plan
		            </h3>
		            <p className="mt-6 text-base leading-6 text-gray-500">
		            	Simple Time and Attendance Software requires simple pricing. We charge £3.50 per employee per month - but in all likelihood, TimeKeeper
		            	should save you more than that in lost time and payroll.
		            </p>
		            <p className="mt-6 text-sm leading-6 text-gray-500 italic">
						Save 10% by opting for annual payment, along with a 2-month discount on any paid add-ons. If you have more than 50 staff, you will receive a 10% discount for the 51st employee and above (£3.15 per employee). Prices are exclusive of VAT.
		            </p>
		            <div className="mt-8">
		              <div className="flex items-center">
		                <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-highlight-600">
		                  Features
		                </h4>
		                <div className="flex-1 border-t-2 border-gray-200"></div>
		              </div>
		              <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
		                {FEATURES.map((feature, idx) =>
		                	<li key={idx} className="flex items-start lg:col-span-1 lg:mt-0">
			                  <div className="flex-shrink-0">
			                    <svg className="h-5 w-5 text-highlight-400" fill="currentColor" viewBox="0 0 20 20">
			                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
			                    </svg>
			                  </div>
			                  <p className="ml-3 text-sm leading-5 text-gray-500">
			                    {feature}
			                  </p>
			                </li>
		                )}

		              </ul>
		            </div>
		          </div>
		          <div className="py-8 px-6 text-center bg-gray-900 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
		            <p className="text-lg leading-6 font-medium text-white">
		              Per employee per month
		            </p>
		            <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-white">
		              <span>
		                £3.50
		              </span>
		              <span className="ml-3 text-xl leading-7 font-medium text-white">
		                GBP (Excl. VAT)
		              </span>
		            </div>
		            <div className="mt-6">
		              <div className="rounded-md shadow">
		                <a href="https://app.timekeeper.co.uk/register" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-500 hover:bg-highlight-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
		                  Start your 14 day free trial
		                </a>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
    	<FAQ />
    	<LaganEngineeringTestimonial />
		<Footer/>
	</Layout>
)

export default Pricing;
